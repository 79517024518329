type Config = {
  url: string;
  apiKey: string;
  recommendationsApiUrl: string;
};

export const klevuConfig: Config = {
  url: 'https://eucs3v2.ksearchnet.com/cs/v2/search',
  apiKey: 'klevu-14903480948925715',
  recommendationsApiUrl: 'https://config-cdn.ksearchnet.com/recommendations/',
};
