import { __pagesText } from '@constants/pages.text';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  KlevuFetch,
  KlevuKMCSettings,
  KlevuRecord,
  KlevuTypeOfRecord,
  KMCRootObject,
  personalisation,
  search,
  suggestions,
} from '@klevu/core';
import { getCookie, setCookie } from 'cookies-next';
import Link from 'next/link';

interface _props {
  screen?: 'MOBILE' | 'DESKTOP';
  // eslint-disable-next-line no-unused-vars
  onSearchInput?: (value?: string) => Promise<void>;
}

const SearchBar: React.FC<_props> = ({
  screen = 'DESKTOP',
  onSearchInput = () => {},
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [showResult, setShowResult] = useState(false);
  const currentCookie = getCookie('searchCust');

  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState<KlevuRecord[]>([]);
  const [suggestion, setSuggetions] = useState<string[]>();
  const [kmcSettings, setKmcSettings] = useState<KMCRootObject>();

  const debounceValue = useDebounce(searchValue, 300);

  const manageCookies = (term: string) => {
    let cookies = [];
    if (currentCookie) {
      cookies = JSON.parse(currentCookie);
      const index = cookies.indexOf(term);
      if (index > -1) {
        cookies.splice(index, 1);
      }
      if (cookies.length === 4) {
        cookies.pop();
      }
    }
    cookies.unshift(term);
    setCookie('searchCust', JSON.stringify(cookies), { maxAge: 60 * 6 * 24 });
  };
  const removeHTML = (str: string) => {
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const searchHandler = () => {
    if (
      searchValue === '' ||
      searchValue === 'Enter Search here' ||
      searchValue.toString().toLowerCase().indexOf('enter search') > -1
    ) {
      return alert('Please enter something to search');
    }
    var str = searchValue.replace(/^\s+|\s+$/g, '');
    while (str.substring(str.length - 1, str.length) == ' ') {
      str = str.substring(0, str.length - 1);
    }
    if (str.length < 3) {
      alert('Please enter at least 3 characters to search');
      searchRef?.current?.focus();
      return;
    }
    manageCookies(str);
    window.location.href =
      '/search/result.html?q=' +
      encodeURIComponent(str.replace(/^\s+|\s+$/g, ''));
  };

  const searchModifiers = [personalisation()];
  const doSearch = async (term: string) => {
    try {
      if (term.length < 1) {
        setProducts([]);
        return;
      }
      const result = await KlevuFetch(
        search(
          term,
          {
            limit: 3,
            typeOfRecords: [KlevuTypeOfRecord.Product],
            id: 'productList',
          },
          ...searchModifiers,
        ),
        suggestions(term, {
          id: 'suggestions',
        }),
      );
      const searchResult = result.queriesById('productList');
      const suggetion = result.suggestionsById('suggestions');
      if (searchResult?.query?.records) {
        setProducts(searchResult.query.records ?? []);
      }
      if (suggetion) {
        setSuggetions(suggetion.suggestions.map((sugg) => sugg.suggest));
      }
    } catch (error) {
      setProducts([]);
      console.error(error);
    }
  };

  const onSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const fetchKMCSettings = async () => {
    const settings = await KlevuKMCSettings(true);
    setKmcSettings(settings.root);
  };

  useEffect(() => {
    fetchKMCSettings();
  }, []);

  const handleOnFocus = () => {
    setShowResult(true);
    if (debounceValue) {
      doSearch(debounceValue);
    }
  };

  useEffect(() => {
    doSearch(debounceValue);
  }, [debounceValue]);

  const popularSearchClick = (term: string) => {
    setSearchValue(term);
    doSearch(term);
  };

  return (
    <OutsideAlerter
      clickHandler={() => {
        setShowResult(false);
      }}
      className={
        screen === 'MOBILE'
          ? 'md:hidden'
          : 'hidden md:inline-block pl-[20px] relative'
      }
    >
      <Formik initialValues={{ text: '' }} onSubmit={searchHandler}>
        {({ values, handleSubmit, handleChange, handleReset }) => {
          return (
            <div>
              <div>
                <Form className='sm:flex xl:max-w-[190px] sm:order-1 w-full'>
                  <div>
                    <div className='border border-tertiary border-sm pt-[5px] pb-[4px] pl-[10px] pr-[30px] text-tertiary relative'>
                      <input
                        ref={searchRef}
                        type='text'
                        min={1}
                        placeholder={__pagesText.Headers.searchPlaceholder}
                        onChange={onSearchChange}
                        onFocus={handleOnFocus}
                        className='bg-transparent outline-none w-full border-0 focus:ring-0 text-[14px] tracking-[1.25px] text-[#ffffff] h-[26px]'
                        autoComplete='off'
                        maxLength={255}
                        value={searchValue}
                        defaultValue={values.text}
                      />
                      <button
                        className='w-[24px] h-[24px] absolute right-[6px] top-[6px]'
                        onClick={() => {
                          handleSubmit();
                          handleReset();
                        }}
                      >
                        <span
                          className='material-icons text-tertiary font-[900]'
                          onClick={() => searchRef?.current?.focus()}
                        >
                          {__pagesText.Headers.searchIcon}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
      {showResult && (
        <div
          className='klevuWrap absolute top-30 md:top-[36px] right-0 md:right-[-15px] w-screen md:max-w-[384px] lg:max-w-[966px]'
          //className='klevuWrap absolute text-center left-[62px] top-36 xl:top-[32px] text-[13px] rounded-[3px] w-screen max-w-[966px]'
          style={{ zIndex: 99999 }}
        >
          <div className='container mx-auto'>
            {kmcSettings?.klevu_webstorePopularTerms &&
              products.length === 0 && (
                <>
                  <div
                    className='w-full text-left bg-white rounded-[3px] shadow-search pb-[5px] custom-klev-search'
                    style={{ boxShadow: '0 1px 5px rgba(50, 50, 50, 0.4)' }}
                  >
                    <ul className='mb-[8px] text-[13px] leading-[14px]'>
                      <li className='SearchHeadline uppercase'>
                        Popular Searches
                      </li>
                      {kmcSettings?.klevu_webstorePopularTerms?.map((item) => (
                        <li
                          className='SearchList'
                          onClick={() => popularSearchClick(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    {currentCookie && currentCookie.length > 0 && (
                      <ul className='mb-[8px] text-[13px] leading-[14px]'>
                        <li className='SearchHeadline pb-[10px] uppercase'>
                          Recent Searches
                        </li>
                        {JSON.parse(currentCookie)?.map((item: string) => (
                          <li
                            className='SearchList'
                            onClick={() => popularSearchClick(item)}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </>
              )}

            {products.length > 0 && (
              <>
                <div className='flex justify-end'>
                  <div
                    className='w-full text-left bg-white rounded-[3px] shadow-search text-[13px] max-w-[450px]'
                    style={{ boxShadow: '0 1px 5px rgba(50, 50, 50, 0.4)' }}
                  >
                    {suggestion && suggestion.length > 0 && (
                      <ul className='py-[5px] px-[10px]'>
                        <li className='uppercase py-[5px]'>Suggetions</li>
                        {suggestion?.map((item: string) => (
                          <li
                            onClick={() => popularSearchClick(removeHTML(item))}
                            title={removeHTML(item)}
                            className='py-[5px] hover:bg-[#f5f5f5]'
                          >
                            <span dangerouslySetInnerHTML={{ __html: item }} />
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className='text-[13px] leading-[130%] border-t'>
                      <div className=''>
                        <div className='flex flex-wrap items-start justify-between pt-[12px] pb-[12px] pl-[15px] pr-[15px]'>
                          <div className='uppercase'>Products</div>
                          <a
                            onClick={searchHandler}
                            className='button text-[10px] leading-[12px] border-b-2 border-[#cccccc] pt-[2px] pb-[2px]'
                          >
                            VIEW ALL
                          </a>
                        </div>
                        {products.map((prodct) => (
                          <Link
                            href={prodct.url}
                            className='p-[8px] block border-b'
                          >
                            <div className='grid grid-cols-10 items-start'>
                              <div className='col-span-2 p-[5px] flex items-center justify-center'>
                                <div className='h-[70px]'>
                                  <img
                                    src={prodct.imageUrl}
                                    alt={prodct.name}
                                    className='max-h-full'
                                  />
                                </div>
                              </div>
                              <div className='col-span-8 p-[5px]'>
                                <div className='ml-[8px] line-clamp-1 font-bold'>
                                  {prodct.name}
                                </div>
                                <div className='ml-[8px] font-bold'>
                                  $ {prodct.salePrice}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </OutsideAlerter>
  );

  return <></>;
};

export default SearchBar;

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
function useOutsideAlerter(
  ref: any,
  clickHandler: () => void,
  exception: Element | null = null,
) {
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (!exception) {
        if (ref.current && !ref.current.contains(event.target)) {
          clickHandler();
        }
      } else if (
        ref.current &&
        !ref.current.contains(event.target) &&
        exception &&
        !exception?.contains(event.target)
      ) {
        clickHandler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, exception]);
}

function OutsideAlerter(props: any) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.clickHandler, props.exception);

  return (
    <div className={props.className} ref={wrapperRef}>
      {props.children}
    </div>
  );
}
