export interface _location {
  country_code: string;
  country: string;
  city: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  ip_address: string;
  region: string;
}

export default async function getLocation() {
  return {
    country_code: '',
    country: '',
    city: '',
    postal_code: '',
    latitude: 0,
    longitude: 0,
    ip_address: '192.168.1.1',
    region: '',
  };
}

interface IGeoLocationResponse {
  country_code: string;
  country_name: string;
  city: string;
  postal: string;
  latitude: number;
  longitude: number;
  IPv4: string;
  state: string;
}

export const fetchZipcodefromGeolocation =
  async (): Promise<IGeoLocationResponse | null> => {
    try {
      const geoIp = await fetch('https://geolocation-db.com/json/').then(
        (response) => response.json(),
      );
      return geoIp;
    } catch (error) {
      console.log('Error: GEO Location not found.', error);
      return null;
    }
  };
